import { formatNumber, zeroPad } from "./format";
import { fieldOptionLabel } from "./forms";
import { mergeDeep } from "./objects";
import {
    postcodeValidator,
    postcodeValidatorExistsForCountry,
} from "./postcodes";

export function buildChoiceIndex(choiceList) {
    const index = {};
    for (const option of choiceList) {
        if (!(option["list_name"] in index)) {
            index[option["list_name"]] = [];
        }
        index[option["list_name"]].push(option);
    }
    return index;
}

export function buildSurveyParamIndex(paramsList) {
    const index = {};
    for (let param of paramsList) {
        index[param.name] = param.value;
    }
    return index;
}

export function mergeDefaults(schemaDefaults, surveyDefaults) {
    return mergeDeep(schemaDefaults, surveyDefaults);
}

export function generateYupMethodOneOfOptions(optionsIndex) {
    return function yupMethodOneOfOptions(list, allowNone) {
        return this.test("oneOfOptions", "No option selected.", (value) => {
            if (allowNone && !value) return true;

            let optionsList = [];
            // list of options given
            if (Array.isArray(list)) {
                optionsList = list;
            } else if (optionsIndex && list in optionsIndex) {
                // name of list given
                optionsList = optionsIndex[list];
            }

            for (let option of optionsList) {
                if (option["name"] && option["name"] == value) return true;
            }
            return false;
        });
    };
}

export function generateYupMethodConditionalOneOfOptions(optionsIndex) {
    return function yupMethodConditionalOneOfOptions(
        list,
        checkFieldName,
        checkFunc
    ) {
        return this.when(checkFieldName, {
            is: checkFunc,
            then: (s) =>
                s.test("oneOfOptions", "No option selected.", (value) => {
                    let optionsList = [];
                    // list of options given
                    if (Array.isArray(list)) {
                        optionsList = list;
                    } else if (optionsIndex && list in optionsIndex) {
                        // name of list given
                        optionsList = optionsIndex[list];
                    }

                    for (let option of optionsList) {
                        if (option["name"] && option["name"] == value)
                            return true;
                    }
                    return false;
                }),
            otherwise: (s) => s,
        });
    };
}

export function generateYupMethodLimitsWithUnit(optionsIndex, convertUnit) {
    return function limitsWithUnit(
        unitListName,
        fieldName,
        { unit, min, max }
    ) {
        return this.test({
            name: "limitsWithUnit",
            test: (v, yupContext) => {
                const { value, unit: valueUnit } = getUnitValue(
                    fieldName,
                    yupContext
                );

                const lowerLimit =
                    min !== undefined &&
                    convertUnit(unitListName, min, unit, valueUnit);
                const upperLimit =
                    max !== undefined &&
                    convertUnit(unitListName, max, unit, valueUnit);

                if (min && value < lowerLimit)
                    return yupContext.createError({
                        message: `Value may not be lower than ${formatNumber(
                            lowerLimit,
                            2
                        )} ${valueUnit}.`,
                        path: yupContext.path,
                    });

                if (max && value > upperLimit)
                    return yupContext.createError({
                        message: `Value may not be higher than ${formatNumber(
                            upperLimit,
                            2
                        )} ${valueUnit}.`,
                        path: yupContext.path,
                    });

                return true;
            },
            message:
                "Value may not be lower than ${min} or higher than ${max} ${unit}.",
            params: {
                min: min,
                max: max,
                unit: unit,
            },
        });
    };
}

export function yupMethodRequiredWhen(fieldName, checkFunc) {
    return this.when(fieldName, {
        is: checkFunc,
        then: (s) => s.required(),
        otherwise: (s) => s,
    });
}

export function yupMethodNanToUndefined() {
    return this.transform((value) => (isNaN(value) ? undefined : value));
}

export function yupTestPostCode(value, yupContext) {
    const country = yupContext?.parent?.country;

    if (!postcodeValidatorExistsForCountry(country)) return true;

    return postcodeValidator(value, country);
}

export function generateSelectOptions(optionsIndex) {
    return function selectOptions(listName) {
        if (optionsIndex && listName in optionsIndex) {
            return optionsIndex[listName];
        }
        return [];
    };
}

export function generateGetOption(optionsIndex) {
    return function getOption(listName, name) {
        if (optionsIndex && listName in optionsIndex) {
            const list = optionsIndex[listName];
            for (let l of list) {
                if (l["name"] == name) return l;
            }
        }
        return null;
    };
}

export function generateDefaultUnits(optionsIndex) {
    return function defaultUnit(unitListName, unitSystem) {
        if (optionsIndex && unitListName in optionsIndex) {
            const list = optionsIndex[unitListName];
            for (let unit of list) {
                if (
                    `default_${unitSystem}` in unit &&
                    unit[`default_${unitSystem}`] == true
                )
                    return unit;
            }
            return list.length > 0 ? list[0] : null;
        }
        return null;
    };
}

export function generateConvertUnit(optionsIndex) {
    return function convertUnit(unitListName, value, valueUnit, toUnit) {
        if (optionsIndex && unitListName in optionsIndex) {
            const list = optionsIndex[unitListName];
            const unitFromOption = list.find((v) => v.name == valueUnit);
            const unitToOption = list.find((v) => v.name == toUnit);

            if (
                !unitFromOption ||
                !unitToOption ||
                !unitFromOption.conversion_factor
            )
                return 0;

            const newValue =
                (value * unitToOption.conversion_factor) /
                unitFromOption.conversion_factor;

            return newValue;
        }
        return null;
    };
}

export function generateResolveFractionUnits(optionsIndex) {
    return function resolveFractionUnits(unitListName, unit) {
        if (optionsIndex && unitListName in optionsIndex) {
            const list = optionsIndex[unitListName];
            const unitOption = list.find((u) => u.name == unit);

            if (
                !unitOption ||
                !unitOption.numerator_list_name ||
                !unitOption.numerator_unit ||
                !unitOption.denominator_list_name ||
                !unitOption.denominator_unit
            )
                return undefined;

            return {
                numeratorListName: unitOption.numerator_list_name,
                numeratorUnit: unitOption.numerator_unit,
                denominatorListName: unitOption.denominator_list_name,
                denominatorUnit: unitOption.denominator_unit,
            };
        }
        return undefined;
    };
}

export function generateTestTotalsAgainstWeightAreaRate(convertUnit) {
    return function testTotalsAgainstWeightAreaRate(
        rateMinTHa,
        rateMaxTHa,
        totalWeightFieldName,
        totalAreaFieldName
    ) {
        return function (val, yupContext) {
            const { value: weightValue, unit: weightUnit } = getUnitValue(
                totalWeightFieldName,
                yupContext
            );
            const { value: areaValue, unit: areaUnit } = getUnitValue(
                totalAreaFieldName,
                yupContext
            );

            const weightUnitFactor = convertUnit(
                "weight",
                1,
                "tonne",
                weightUnit
            );
            const areaUnitFactor = convertUnit("area", 1, "ha", areaUnit);

            if (!areaValue || !areaUnitFactor) return;

            const rateMin = (rateMinTHa * weightUnitFactor) / areaUnitFactor;
            const rateMax = (rateMaxTHa * weightUnitFactor) / areaUnitFactor;

            const yieldPerArea = weightValue / areaValue;

            if (yieldPerArea < rateMin)
                return yupContext.createError({
                    message: `Area yield may not be lower than ${formatNumber(
                        rateMin,
                        2
                    )} ${weightUnit} / ${areaUnit}.`,
                    path: yupContext.path,
                });

            if (yieldPerArea > rateMax)
                return yupContext.createError({
                    message: `Area yield may not be higher than ${formatNumber(
                        rateMax,
                        2
                    )} ${weightUnit} / ${areaUnit}.`,
                    path: yupContext.path,
                });

            return true;
        };
    };
}

export function generateTestDieselWithinRangeOption(getOption, convertUnit) {
    return function testDieselWithinRangeOption(
        rangeOptionListName,
        rangeOptionNameField,
        estimateFieldName,
        dieselKnownField
    ) {
        return function (val, yupContext) {
            const dieselKnown = yupContext?.parent?.[dieselKnownField];
            if (dieselKnown == "yes") {
                return true;
            }

            const rangeValue = yupContext?.parent?.[rangeOptionNameField];
            const option = getOption(rangeOptionListName, rangeValue);
            if (!option) {
                return yupContext.createError({
                    message: `Select a range first.`,
                    path: yupContext.path,
                });
            }

            const { value, unit } = getUnitValue(estimateFieldName, yupContext);
            const valueLiters = convertUnit(
                "volume_area_rate",
                value,
                unit,
                "litre / ha"
            );

            if (valueLiters == null) {
                return;
            }

            const limitMax = option?.properties?.LIMIT_MAX;
            if (limitMax) {
                if (valueLiters > limitMax) {
                    return yupContext.createError({
                        message: `Value may not be higher than ${formatNumber(
                            limitMax,
                            2
                        )}.`,
                        path: yupContext.path,
                    });
                }
            }

            const limitMin = option?.properties?.LIMIT_MIN;
            if (limitMin) {
                if (valueLiters < limitMin) {
                    return yupContext.createError({
                        message: `Value may not be lower than ${formatNumber(
                            limitMin,
                            2
                        )}.`,
                        path: yupContext.path,
                    });
                }
            }

            return true;
        };
    };
}

export function generateTestFertilizerNRate(convertUnit) {
    return function testFertilizerNRate(
        rateMinKgHa,
        rateMaxKgHa,
        getOption,
        list_name
    ) {
        return function (fertilizerList, yupContext) {
            let nTotalKgHa = 0;
            for (let f of fertilizerList) {
                const fertilizer = getOption(list_name, f?.type);
                const applicationRate = f?.application_rate || 0;
                const rateUnit = f?.application_rate__unit;
                const convertedApplicationRate = convertUnit(
                    "weight_area_rate",
                    applicationRate,
                    rateUnit,
                    "kg / ha"
                );
                if (fertilizer.name == "Custom") {
                    const { n } = getCustomNPKContents(f, convertUnit);

                    nTotalKgHa += (n / 100) * convertedApplicationRate || 0;
                } else {
                    nTotalKgHa +=
                        (parseFloat(fertilizer?.nutrients?.n) / 100) *
                            convertedApplicationRate || 0;
                }
            }

            if (nTotalKgHa < rateMinKgHa)
                return yupContext.createError({
                    message: `Total nitrogen fertilization may not be less than ${formatNumber(
                        rateMinKgHa,
                        2
                    )} kg N/ha.`,
                    path: yupContext.path,
                });

            if (nTotalKgHa > rateMaxKgHa)
                return yupContext.createError({
                    message: `Total nitrogen fertilization may not be more than ${formatNumber(
                        rateMaxKgHa,
                        2
                    )} kg N/ha.`,
                    path: yupContext.path,
                });

            return true;
        };
    };
}

export function generateTestCustomFertilizerTotalPercentage(convertUnit) {
    return function testCustomFertilizerTotalPercentage(getOption, list_name) {
        return function (fertilizerList, yupContext) {
            for (let f of fertilizerList) {
                const fertilizer = getOption(list_name, f?.type);
                if (fertilizer.name != "Custom") continue;
                const { n, p2o5, k2o } = getCustomNPKContents(f, convertUnit);
                const totalPercentage = n + p2o5 + k2o;

                if (totalPercentage > 100)
                    return yupContext.createError({
                        message: `Sum of ingredients of custom N-P-K fertilizers may not exceed 100%.`,
                        path: yupContext.path,
                    });
            }

            return true;
        };
    };
}

export function optionLabel(uiSchema, value, language) {
    if (!uiSchema?.options) return null;

    for (let option of uiSchema.options) {
        if (option?.name && option.name == value) {
            return fieldOptionLabel(option, language);
        }
    }
}

export function unitShortLabel(unitOption) {
    if (!unitOption) return null;

    return unitOption.label_short;
}

export function getUnitValue(fieldName, yupContext) {
    const parentFieldData = yupContext.parent;

    if (fieldName in parentFieldData && `${fieldName}__unit` in parentFieldData)
        return {
            value: parentFieldData[fieldName],
            unit: parentFieldData[`${fieldName}__unit`],
        };

    return { value: undefined, unit: undefined };
}

export function monthHalfOptions(listName, year, numPreceedingMonths) {
    let numYears = Math.ceil(numPreceedingMonths / 12);
    let monthCounter = numPreceedingMonths;

    const items = [];

    let yearIndex = 0;
    while (yearIndex < numYears) {
        let monthIndex = 11;
        while (monthIndex >= 0 && monthCounter > 0) {
            const date = new Date(year - yearIndex, monthIndex);
            const monthStr = date.toLocaleDateString(undefined, {
                year: "numeric",
                month: "long",
            });
            const lastDayOfMonth = new Date(
                year - yearIndex,
                monthIndex + 1,
                0
            );
            const firstHalf = "01.-15.";
            const secondHalf = `16.-${lastDayOfMonth.getDate()}.`;

            const monthNumStr = zeroPad(monthIndex + 1, 2); // add leading zero
            const monthValue = `${year - yearIndex}-${monthNumStr}`;

            items.push({
                list_name: listName,
                name: `${monthValue}-01`,
                label: `${firstHalf} ${monthStr}`,
            });

            items.push({
                list_name: listName,
                name: `${monthValue}-16`,
                label: `${secondHalf} ${monthStr}`,
            });

            monthIndex--;
            monthCounter--;
        }
        yearIndex++;
    }

    return items;
}

export function getCustomNPKContents(fertilizerData, convertUnit) {
    if (fertilizerData?.type != "Custom") {
        return { n: 0, p2o5: 0, k2o: 0 };
    }
    const n =
        (parseFloat(fertilizerData.custom_N_ammonium_share) || 0) +
        (parseFloat(fertilizerData.custom_N_nitrate_share) || 0) +
        (parseFloat(fertilizerData.custom_N_urea_share) || 0);

    const p2o5 = convertUnit(
        "phosphorous_nutrient",
        parseFloat(fertilizerData.custom_P_share) || 0,
        fertilizerData.custom_P_share__unit,
        "p2o5"
    );

    const k2o = convertUnit(
        "potassium_nutrient",
        parseFloat(fertilizerData.custom_K_share) || 0,
        fertilizerData.custom_K_share__unit,
        "k2o"
    );

    return { n, p2o5, k2o };
}
